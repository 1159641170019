<script setup>
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits(['update:showModal'])
const active = ref(false)

watch(
  () => props.showModal,
  (value) => {
    active.value = value
  },
)
</script>

<template>
  <el-dialog v-model="active" title="Important Notice" class="w-full md:w-[500px]" center>
    <span>
      If your payment is for a Search, select
      <strong>Search.</strong>
      If it is for anything else, select
      <strong>Other.</strong>
    </span>
    <template #footer>
      <div class="dialog-footer">
        <a href="/payment" class="mr-6"><el-button type="primary" @click="$emit('update:showModal', false)">Search</el-button></a>
        <a href="/payment-other"><el-button type="primary" @click="$emit('update:showModal', false)">Other</el-button></a>
      </div>
    </template>
  </el-dialog>
</template>
