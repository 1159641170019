<script setup>
import logo from '@/assets/images/avrillo-logo.png'
import { useAuthStore } from '~/store/auth'
import { useConveyancingCaseStore } from '~/store/conveyancing-case'
import { UserFilled, Expand } from '@element-plus/icons-vue'

const route = useRoute()

const authStore = useAuthStore()

const conveyancingCaseStore = useConveyancingCaseStore()

const { currentlyInsideProclaimMaintenanceWindow } = useEnvironment()

const client = computed(() => authStore.client)
const showModal = shallowRef(false)

const conveyancingCase = computed(() => conveyancingCaseStore.conveyancingCase)

const navigationItems = computed(() => [
  { name: 'Milestones', href: '/milestones', hidden: false },
  {
    name: 'Case History',
    href: '/case-history',
    hidden: false,
    disabled: currentlyInsideProclaimMaintenanceWindow.value,
    disabledMessage: 'Case History is unavailable until 7am',
  },
  {
    name: 'Purchase Documents',
    active: route.params?.matter_type === 'purchase',
    children: [
      { name: '1st Letter Documents to Sign', href: '/documents/purchase/first-letter?page=1&perPage=10&corr_type=SSL' },
      { name: 'Report on Title', href: '/documents/purchase/report-on-title?page=1&perPage=10&corr_type=SSL' },
    ],
    hidden: conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'S',
  },
  {
    name: 'Sale Documents',
    active: route.params?.matter_type === 'sale',
    children: [{ name: '1st Letter Documents to Sign', href: '/documents/sale/first-letter?page=1&perPage=10&corr_type=SSL' }],
    hidden: conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'P',
  },
  {
    name: 'Payment',
    active: route.name === 'payment' || route.name === 'payment-other',
    href: 'https://avrillo.co.uk/online-payment',
    target: '_blank',
    showModal: true,
    hidden:
      conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'P' || conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'B',
  },
  {
    name: 'Payment',
    active: route.name === 'payment' || route.name === 'payment-other',
    href: '/payment',
    hidden: conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'S',
    showModal: true,
  },
  {
    name: 'Call Surgery',
    href: conveyancingCase?.value?.fee_earner?.outlook_book_url || 'https://avrillo.co.uk/legal-surgery/',
    target: '_blank',
  },
])

function handleLogout() {
  authStore.logout()
}
</script>

<template>
  <div>
    <header class="bg-white shadow">
      <div class="flex items-center justify-between gap-6 px-6 py-3 lg:px-20">
        <nuxt-link to="/">
          <img :src="logo" alt="Logo" class="h-auto min-w-[75px]" />
        </nuxt-link>
        <nav class="hidden items-center justify-center gap-3 md:flex lg:gap-6">
          <template v-for="navigationItem in navigationItems" :key="navigationItem.href">
            <section v-if="!navigationItem.hidden">
              <el-dropdown v-if="!!navigationItem.children">
                <p
                  :class="navigationItem.active ? 'active-dropdown' : ''"
                  class="relative cursor-pointer text-sm font-semibold uppercase text-gray-900 outline-none hover:text-primary"
                >
                  {{ navigationItem.name }}
                </p>
                <template #dropdown>
                  <el-dropdown-menu>
                    <nuxt-link
                      v-for="child in navigationItem.children"
                      :key="child.href"
                      :to="child.href"
                      :target="child.target ?? '_self'"
                    >
                      <el-dropdown-item :class="child.classes?.join(' ')">
                        {{ child.name }}
                      </el-dropdown-item>
                    </nuxt-link>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <el-tooltip
                v-else
                :content="navigationItem?.disabledMessage"
                placement="bottom"
                effect="light"
                :disabled="!navigationItem?.disabled || !navigationItem?.disabledMessage"
              >
                <div class="group">
                  <nuxt-link
                    :to="navigationItem.showModal || navigationItem?.disabled ? '' : navigationItem.href"
                    :target="navigationItem.target ?? '_self'"
                    :class="{ disabled: navigationItem?.disabled }"
                    class="relative cursor-pointer text-sm font-semibold uppercase text-gray-900 hover:text-primary group-has-[.disabled]:cursor-not-allowed group-has-[.disabled]:text-gray-200"
                    @click="!navigationItem?.disabled && navigationItem.showModal ? (showModal = true) : null"
                  >
                    {{ navigationItem.name }}
                  </nuxt-link>
                </div>
              </el-tooltip>
            </section>
          </template>
        </nav>
        <div class="flex items-center gap-3">
          <div class="hidden text-right lg:block">
            <el-skeleton :loading="!conveyancingCase?.con_case_ref" :rows="0" animated>
              <p class="text-sm font-bold">{{ conveyancingCase?.con_case_ref }}</p>
            </el-skeleton>
            <p class="text-xs text-gray-500">{{ client?.name }}</p>
          </div>
          <el-dropdown type="success" placement="bottom-end" size="large">
            <template #default>
              <div class="">
                <el-avatar :icon="UserFilled" class="hidden items-center justify-center hover:bg-primary md:flex" />
                <el-avatar :icon="Expand" class="flex items-center justify-center md:hidden" />
              </div>
            </template>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="w-36 px-5 pb-2">
                  <p class="text-sm font-bold">{{ conveyancingCase?.con_case_ref }}</p>
                  <p class="truncate text-xs text-gray-500">{{ client?.name }}</p>
                </div>
                <hr />
                <template v-for="navigationItem in navigationItems" :key="navigationItem.route">
                  <section v-if="!navigationItem.hidden">
                    <div v-if="!navigationItem.children" class="group">
                      <nuxt-link
                        :to="navigationItem.showModal || navigationItem?.disabled ? '' : navigationItem.href"
                        :target="navigationItem.target ?? '_self'"
                        :class="{ disabled: navigationItem?.disabled }"
                        @click="!navigationItem?.disabled && navigationItem.showModal ? (showModal = true) : null"
                      >
                        <el-dropdown-item class="group-has-[.disabled]:text-gray-200">
                          {{ navigationItem.name }}
                        </el-dropdown-item>
                      </nuxt-link>
                    </div>
                    <template v-else>
                      <hr />
                      <p class="px-3 py-2 text-xs">{{ navigationItem.name }}</p>
                      <nuxt-link v-for="item in navigationItem.children" :key="item.href" :to="item.href">
                        <el-dropdown-item>
                          {{ item.name }}
                        </el-dropdown-item>
                      </nuxt-link>
                      <hr />
                    </template>
                  </section>
                </template>
                <nuxt-link to="/payment-details">
                  <el-dropdown-item>AVRillo Bank Details</el-dropdown-item>
                </nuxt-link>
                <el-dropdown-item @click="handleLogout">Logout</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </header>
    <DialogsPaymentTypes :show-modal="showModal" @update:show-modal="showModal = false" @closed="showModal = false" />
  </div>
</template>

<style scoped lang="postcss">
nav .router-link-active,
nav .active-dropdown {
  @apply text-primary !important;
}

nav .router-link-active::before,
nav .active-dropdown::before {
  @apply absolute -left-3 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full bg-primary/20;
  content: '';
}

nav .el-dropdown {
  @apply flex !important;
}
</style>
