<script setup>
const { currentEnvironemnt, isProduction, currentlyInsideProclaimMaintenanceWindow } = useEnvironment()

const addBannerPadding = computed(() => {
  if (isProduction.value && !currentlyInsideProclaimMaintenanceWindow.value) {
    return false
  }

  return true
})
</script>

<template>
  <NuxtLoadingIndicator />
  <div class="fixed inset-x-0 top-0 z-50">
    <InfoBanner v-if="!isProduction" :content="currentEnvironemnt + ' Environment'" />
    <InfoBanner
      v-else-if="currentlyInsideProclaimMaintenanceWindow"
      content="To maintain safety of your documents, a routine backup takes place overnight. Document viewing will be unavailable between 11pm and 7am."
      state-class="bg-amber-500"
    />
    <NavBar />
  </div>

  <main class="px-6 py-6 md:px-6" :class="addBannerPadding ? 'mt-[110px]' : 'mt-[72px]'">
    <slot />
  </main>
</template>
